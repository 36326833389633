export default {
  'DESHACERULTIMAACCION': 'undo',
  'REHACERULTIMAACCION': 'redo',
  'FUENTE': 'font',
  'TAMANODEFUENTE':'fontSize',
  'FORMATO':'formatBlock',
  'ESTILODELPARRAFO':'paragraphStyle',
  'CITA':'blockquote',
  'NEGRITA':'bold',
  'SUBRAYADO':'underline',
  'CURSIVA':'italic',
  'TACHADO':'strike',
  'SUBINDICE':'subscript',
  'SUPERINDICE':'superscript',
  'COLORDEFUENTE':'fontColor',
  'COLORDERESALTADO':'hiliteColor',
  'ESTILODELTEXTO':'textStyle',
  'ELIMINARFORMATO':'removeFormat',
  'MENOSTABULACION':'outdent',
  'MASTABULACION':'indent',
  'ALINEAR':'align',
  'LINEAHORIZONTAL':'horizontalRule',
  'LISTA':'list',
  'ALTURADELALINEA':'lineHeight',
  'TABLA':'table',
  'LINK':'link',
  'IMAGEN':'image',
  'PANTALLACOMPLETA':'fullScreen',
  'VERBLOQUES':'showBlocks',
  'VERCODIGOFUENTE':'codeView',
  'VISTAPREVIA':'preview',
  'PLANTILLA':'template',
  'MOREMISC':':i-More Misc-default.more_vertical',
  'MORERICH':':r-More Rich-default.more_plus',
  'GALERIADEIMAGENES':'imageGallery',
  'MOREPARAGRAPH':':p-More Paragraph-default.more_paragraph',
  'MORETEXT':':t-More Text-default.more_text',
  'MORELINE':':e-More Line-default.more_horizontal'
}