import React from 'react';
import ReactDOM from 'react-dom';
import HSCRTFInput from './HSCRTFInput/HSCRTFInput';


// uncomment this block to "defer" ReactDOM.render and expose it globaly
window.HSCRTFInput = {
    mount: (props, container) => {
        ReactDOM.render(<HSCRTFInput {...props} />, container);
    },
    unmount: (container) => {
        ReactDOM.unmountComponentAtNode(container);
    }
}


console.log("HSCRTFInput version: v0.0.5.9")